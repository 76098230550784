.fade-right{
  animation: fadeInRight 1s ease-in-out;
  opacity: 1;
}

.fade-right-1{
  animation: fadeInRight1 2s ease-in-out;
  opacity: 1;
}

.fade-right-2{
  animation: fadeInRight2 1.8s ease-in-out;
  opacity: 1;
}

.fade-right-3{
  animation: fadeInRight3 1.6s ease-in-out;
  opacity: 1;
}

.fade-right-4{
  animation: fadeInRight4 1.4s ease-in-out;
  opacity: 1;
}

.fade-right-5{
  animation: fadeInRight5 1.2s ease-in-out;
  opacity: 1;
}

.fade-left{
  animation: fadeInLeft 1s ease-in-out;
  opacity: 1;
}

.fade-left-1{
  animation: fadeInLeft1 2s ease-in-out;
  opacity: 1;
}

.fade-left-2{
  animation: fadeInLeft2 1.8s ease-in-out;
  opacity: 1;
}

.fade-left-3{
  animation: fadeInLeft3 1.6s ease-in-out;
  opacity: 1;
}

.fade-left-4{
  animation: fadeInLeft4 1.4s ease-in-out;
  opacity: 1;
}

.fade-left-5{
  animation: fadeInLeft5 1.2s ease-in-out;
  opacity: 1;
}

.fade-top-1{
  animation: fadeInTop 1s ease-in-out;
  opacity: 1;
}

.fade-top-2{
  animation: fadeInTop 1.3s ease-in-out;
  opacity: 1;
}

.fade-top-3{
  animation: fadeInTop 1.6s ease-in-out;
  opacity: 1;
}

.fade-top-4{
  animation: fadeInTop 1.9s ease-in-out;
  opacity: 1;
}

.fade-bottom{
  animation: fadeInDown 1s ease-in-out;
  opacity: 1;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(30vh);
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInRight1 {
  from {
    opacity: 0;
    transform: translateX(30vh);
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInRight2 {
  from {
    opacity: 0;
    transform: translateX(40vh);
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInRight3 {
  from {
    opacity: 0;
    transform: translateX(50vh);
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInRight4 {
  from {
    opacity: 0;
    transform: translateX(60vh);
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInRight5 {
  from {
    opacity: 0;
    transform: translateX(70vh);
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-30vh);
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInLeft1 {
  from {
    opacity: 0;
    transform: translateX(-30vh);
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInLeft2 {
  from {
    opacity: 0;
    transform: translateX(-40vh);
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInLeft3 {
  from {
    opacity: 0;
    transform: translateX(-50vh);
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInLeft4 {
  from {
    opacity: 0;
    transform: translateX(-60vh);
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInLeft5 {
  from {
    opacity: 0;
    transform: translateX(-70vh);
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInTop {
  from {
    opacity: 0;
    transform: translateY(30vh);
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-30vh);
  }
  to {
    opacity: 1;
  }
}