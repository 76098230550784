.footer-container{
    background-color: rgb(16, 31, 70);
    padding-top: 2vh;
}

.footer-icon{
    height: 4vh;
    width: 4vh;
}

.footer-text{
    text-align: center;
    color: aliceblue;
}