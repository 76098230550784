html, body {
    overflow-x: hidden;
}
body{
    font-family: 'Open Sans', sans-serif !important ;
    overflow-x: hidden;
}

.center{
    text-align: center;
}

.inicio-container{
    position: relative;
    padding: 0;
    margin: 0;
}

.navbar-img-container{
    position: relative;
    width: 100%;
    height: 90vh;
    padding: 0;
    margin-bottom: 5vh;
}

.navbar-img{
    width: 100%;
    height: 100%;
    top: -10vh;
    z-index: 0;
    filter: brightness(30%)
}

.diagonal-cover{
    background-color: #a5b147;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    clip-path: polygon(0 0, 38% 0, 25% 100%, 0% 100%);
}

.logo-container{
    position: absolute;
    z-index: 3;
    height: 30vh;
    width: 50vh;
    top: 40%;
    left: 55%;
}

.logo{
    height: 100%;
    width: 100%;
}

canvas{
    position: absolute;
    z-index: 1;
}

.text-container{
    position: absolute;
    z-index: 4;
    width: 50%;
    top: 10vh;
    padding: 2vw;
    padding-right: 15vw;
    padding-top: 15vh;
}

.img-title{
    color: aliceblue;
    font-size: 4vh;
    top: 15vh;
    margin-bottom: 5vh;
    font-family: "Cocogoose", sans-serif;
}

.img-text{
    font-size: 3vh;
    top: 25vh;
    width: 80%;
}

.title-1{
    font-size: 5vh;
    font-family: Cocogoose;
}

.logo-img{
    opacity: 0;
    height: 25vh;
}

.section-container{
    height: 80vh;
}

.diagonal-cover-2{
    background-color: #0f0e0c;
    position: absolute;
    height: 100%;
    z-index: 1;
    clip-path: polygon(55% 0, 100% 0, 100% 100%, 45% 100%);
}

.section-img{
    position: absolute;
    height: 65vh;
    z-index: 2;
    top: 20%;
    left: 60%;
}

.section-img-2{
    position: absolute;
    height: 65vh;
    z-index: 2;
    top: 20%;
    left: 10%;
}

.section-text-container{
    margin-left: 5vh;
    z-index: 1;
}

.section-text-container-2{
    padding-top: 12vh;
    margin-right: 5vh;
    text-align: right;
    z-index: 2;
}

.title-2{
    font-size: 5vh;
    font-family: Cocogoose;
    color: #a5b147;
    opacity: 0;
}

.title-3{
    font-size: 4vh;
    font-family: Cocogoose;
    color: aliceblue;
    margin-bottom: 5vh;
    opacity: 0;
}

.text-2{
    font-size: 2.5vh;
    margin-bottom: 5vh;
    color: aliceblue;
    opacity: 0;
}

.a-btn{
    opacity: 0;
}

.contactanos-btn{
    font-size: 3vh;
    color: aliceblue;
    background-color: #a5b147;
    border: none;
    border-radius: 10px;
    height: 5vh;
    width: 25vh;
}

.diagonal-cover-3{
    background-color: #0f0e0c;
    position: absolute;
    height: 100%;
    z-index: 1;
    clip-path: polygon(0 0, 45% 0, 60% 100%, 0% 100%);
}

.cr-col{
    background-color: #eeeded;
    opacity: 0;
    transition: transform .5s, filter .5s;
}

.cr-col:hover{
    transform: scale(1.08);
    filter: brightness(80%)
}

.col-sm-2{
    width: 19% !important;
}

.cr-img{
    width: 80%;
}

.cr-img-1{
    width: 80%;
    margin-top: 20%;
}

.location-container{
    background-color: #0f0e0c;
}

.mapa-container{
    padding: 5vh;
}

.mapa-img{
    width: 100%;
    border-radius: 40px;
}

.contactanos-container{
    padding: 10%;
    text-align: center;
}

.contact-title{
    color: #a5b147;
    font-size: 7vh;
    margin-bottom: 5vh;
}

.contact-text{
    color: aliceblue;
    font-size: 3vh;
}

@media only screen and (max-width: 1400px) {
    .diagonal-cover-3{
        clip-path: polygon(0 0, 52% 0, 60% 100%, 0% 100%);
    }
    .title-2{
        font-size: 4vh;
    }
    .title-3{
        font-size: 3vh;
    }
    .text-2{
        font-size: 2.4vh;
    }

    .contactanos-container{
        padding: 5%;
        text-align: center;
    }
    
    .contact-title{
        font-size: 5vh;
        margin-bottom: 3vh;
    }
    
    .contact-text{
        color: aliceblue;
        font-size: 2.5vh;
    }
}

@media only screen and (max-width: 991px) {
    .section-container{
        height: auto;
    }
    .title-1{
        font-size: 4vh;
    }
    .title-2{
        font-size: 4vh;
    }
    .title-3{
        font-size: 3vh;
    }
    .text-2{
        font-size: 2.4vh;
    }
    .logo-img{
        height: 15vh;
    }
    .navbar-img-container{
        height: 50vh;
        width: 110%;
    }
    .logo-container{
        top: 25%;
        left: 45%;
        margin-left: -25vw;
        width: 50vw;
    }
    .section-text-container-2{
        text-align: center;
        background-color: #0f0e0c;
        margin: 0;
        padding-top: 5vh;
        padding-bottom: 5vh;
    }
    .section-text-container{
        text-align: center;
        background-color: #0f0e0c;
        margin: 0;
        padding-top: 5vh;
        padding-bottom: 5vh;
    }
    .diagonal-cover-2{
        display: none;
    }
    .diagonal-cover-3{
        display: none;
    }
    
    .contact-title{
        font-size: 4vh;
        margin-bottom: 1vh;
    }
    
    .contact-text{
        color: aliceblue;
        font-size: 2vh;
    }
}

@media only screen and (max-width: 767px) {
    .mapa-container{
        padding: 0vh;
    }
    
    .mapa-img{
        width: 100%;
        border-radius: 0;
    }
}